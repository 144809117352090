import { Ethereum, Sepolia, BaseSepoliaTestnet, Base } from "@thirdweb-dev/chains";

// PROD CONFIG
const _PROD_API = "https://api.tasseo.xyz/api/v1";
// const _PROD_API = "http://192.168.183.238:8000";
// const _PROD_API = "http://localhost:8000";

export const API = {
  GetPhaseDetails: _PROD_API + "/wizard/",
  GetStepDetails: _PROD_API + "/wizard/step",
  GetInitialData: _PROD_API + "/wizard/initial_data",
  GetArtistDetails: _PROD_API + "/artist/",
  GetMembershipDetails: _PROD_API + "/artist/membership",
  GetMemberDetails: _PROD_API + "/member/",
  StoreEmailAddress: _PROD_API + "/member/email",
  ValidateInviteCode: _PROD_API + "/member/referral",
  StoreTransactionDetails: _PROD_API + "/member/transaction",
  GetCollectorDetails: _PROD_API + "/artist/drop_mints_count",
  GetArtistThirdWebClientID: _PROD_API + "/artist/thirdweb_clinet_id",
};

export const WarpcastURL = "https://warpcast.com/";

export const ThirdWebMintEmbedUrlERC721 = (
  contactAddress,
  clientID,
  chain
) => `https://embed.ipfscdn.io/ipfs/bafybeicd3qfzelz4su7ng6n523virdsgobrc5pcbarhwqv3dj3drh645pi/?theme=dark&primaryColor=black
&contract=${contactAddress}
&clientId=${clientID}
&chain=${chain}`;

export const ThirdWebMintEmbedUrlERC1155 = (
  contactAddress,
  clientID,
  tokenId,
  chain
) => `https://embed.ipfscdn.io/ipfs/bafybeigdie2yyiazou7grjowoevmuip6akk33nqb55vrpezqdwfssrxyfy/erc1155.html?theme=dark&primaryColor=black
&contract=${contactAddress}
&clientId=${clientID}
&tokenId=${tokenId}
&chain=${chain}`;

export const ManifoldAuctionURL = (listingID) => `https://gallery.manifold.xyz/listing?listingId=${listingID}`;

const getThirdWebEmbedUrlChainDetails = (chain) => {
  if (chain == "Ethereum") {
    return {
      name: "Ethereum Mainnet",
      chain: "ETH",
      rpc: ["https://ethereum.rpc.thirdweb.com/${THIRDWEB_API_KEY}"],
      nativeCurrency: {
        name: "Ether",
        symbol: "ETH",
        decimals: 18,
      },
      shortName: "eth",
      chainId: 1,
      testnet: false,
      slug: "ethereum",
      icon: {
        url: "ipfs://QmcxZHpyJa8T4i63xqjPYrZ6tKrt55tZJpbXcjSDKuKaf9/ethereum/512.png",
        height: 512,
        width: 512,
        format: "png",
      },
    };
  } else if (chain == "Sepolia") {
    return {
      name: "Sepolia",
      chain: "ETH",
      rpc: ["https://11155111.rpc.thirdweb.com/${THIRDWEB_API_KEY}"],
      nativeCurrency: {
        name: "Sepolia Ether",
        symbol: "ETH",
        decimals: 18,
      },
      shortName: "sep",
      chainId: 11155111,
      testnet: true,
      slug: "sepolia",
      icon: {
        url: "ipfs://QmcxZHpyJa8T4i63xqjPYrZ6tKrt55tZJpbXcjSDKuKaf9/ethereum/512.png",
        width: 512,
        height: 512,
        format: "png",
      },
    };
  } else if (chain == "BaseSepoliaTestnet") {
    return {
      name: "Base Sepolia Testnet",
      chain: "ETH",
      rpc: ["https://84532.rpc.thirdweb.com/${THIRDWEB_API_KEY}"],
      nativeCurrency: {
        name: "Sepolia Ether",
        symbol: "ETH",
        decimals: 18,
      },
      shortName: "basesep",
      chainId: 84532,
      testnet: true,
      slug: "base-sepolia-testnet",
      icon: {
        url: "ipfs://QmaxRoHpxZd8PqccAynherrMznMufG6sdmHZLihkECXmZv",
        width: 1200,
        height: 1200,
        format: "png",
      },
    };
  } else if (chain == "Base") {
    return {
      name: "Base",
      chain: "ETH",
      rpc: ["https://8453.rpc.thirdweb.com/${THIRDWEB_API_KEY}"],
      nativeCurrency: {
        name: "Ether",
        symbol: "ETH",
        decimals: 18,
      },
      shortName: "base",
      chainId: 8453,
      testnet: false,
      slug: "base",
      icon: {
        url: "ipfs://QmW5Vn15HeRkScMfPcW12ZdZcC2yUASpu6eCsECRdEmjjj/base-512.png",
        width: 512,
        height: 512,
        format: "png",
      },
    };
  }
};

const chain = process.env.REACT_APP_CHAIN;
// console.log("chain>>>>>", chain);
export const ThirdWebChain =
  chain == "Ethereum"
    ? Ethereum
    : chain == "BaseSepoliaTestnet"
    ? BaseSepoliaTestnet
    : chain == "Sepolia"
    ? Sepolia
    : chain == "Base"
    ? Base
    : "";
export const ThirdWebClientID = process.env.REACT_APP_ThirdWebClientID;
export const OpenseaCollectionLink = "";
export const OpenseaViewNFTTokenURL = process.env.REACT_APP_OpenseaViewNFTTokenURL;
export const EtherscanTransactionURL = process.env.REACT_APP_EtherscanTransactionURL;
export const ThirdWebEmbedUrlChainDetails = getThirdWebEmbedUrlChainDetails(chain);
