import { useState, useEffect } from "react";
import WizardAPI from "../../wizard-api";
import "./step2.css";
import StepLoader from "../../../common-components/step-loader/step-loader";
import ThirdWebLogo from "../../../common-components/thirdweb-logo/thirdweb-logo";

import { Ethereum, Goerli } from "@thirdweb-dev/chains";
import { useAddress, useConnect } from "@thirdweb-dev/react";
import { MetaMaskWallet, coinbaseWallet, trustWallet, rainbowWallet, walletConnect } from "@thirdweb-dev/react";
import { ThirdWebClientID } from "../../../../../constants/common-constants";
import { useWalletAddress } from "../../../../../hooks/useWalletAddress";
import StepButton from "../../../common-components/step-button/step-button";
import { ThirdWebChain } from "../../../../../constants/common-constants";

const walletConnectConfig = walletConnect();
const coinbaseConfig = coinbaseWallet();
const metamaskWallet = new MetaMaskWallet({
  clientId: ThirdWebClientID,
});
// const metamaskWalletConfig = metamaskWallet();
const trustWalletConfig = trustWallet();
const rainbowWalletConfig = rainbowWallet();

function PostLaunchStep2({ artistId, membershipId, stepChange, disableAllowlist }) {
  const connect = useConnect();
  const [manualRefresh, setManualRefresh] = useState(1); // refresh for useWalletAddress
  const address = useAddress();
  const [loading, setLoading] = useState(false);
  const [walletConnectButtonClicked, setWalletConnectButtonClicked] = useState(false);

  const getMemberDetails = async () => {
    setLoading(true);
    const getMemberRes = await WizardAPI.getMemberDetails(artistId, membershipId, address, "");
    // console.log("post-launch.js/step3.js member details>>>>>>", getMemberRes);
    if (getMemberRes["success"] && getMemberRes["code"] === 200) {
      stepChange(1);
      setLoading(false);
    } else {
      alert("Something went wrong.");
      stepChange(1);
    }
  };

  const handleConnect = async (wallet) => {
    setWalletConnectButtonClicked(true);
    try {
      let w;
      if (wallet === "Metamask") {
        await metamaskWallet.connect();
        // w = await connect(metamaskWalletConfig, Goerli);
        setManualRefresh(manualRefresh + 1);
      } else if (wallet === "Coinbase") {
        w = await connect(coinbaseConfig, ThirdWebChain);
      } else if (wallet === "Rainbow") {
        w = await connect(rainbowWalletConfig, ThirdWebChain);
      } else if (wallet === "Trust-Wallet") {
        w = await connect(trustWalletConfig, ThirdWebChain);
      } else if (wallet === "Wallet-Connect") {
        w = await connect(walletConnectConfig, ThirdWebChain);
      }
      setWalletConnectButtonClicked(false);
    } catch (error) {
      // console.log("error >>>>>>>>>>", error);
      setWalletConnectButtonClicked(false);
    }
  };

  useEffect(() => {
    if (disableAllowlist) {
      stepChange(1);
    }
    if (address) {
      getMemberDetails();
    }
  }, [address]);

  return (
    <>
      {loading ? (
        <StepLoader />
      ) : (
        <div className="mobile-post-launch-step3-container">
          {walletConnectButtonClicked ? (
            <div className="mobile-wallet-connecting-container">
              <div className="mobile-wallet-connecting-text">
                Please complete your wallet signin. Signing helps us know that you are the owner of the wallet
              </div>
            </div>
          ) : (
            ""
          )}
          <div className="mobile-connect-wallet-option-container" onClick={() => handleConnect("Wallet-Connect")}>
            <StepButton buttonText="Connect Wallet" width="150px" height="45px" />
          </div>

          <div className="mobile-post-launch-step3-thirdweb-logo-container">
            <ThirdWebLogo width="140px" />
          </div>
        </div>
      )}
    </>
  );
}

export default PostLaunchStep2;
